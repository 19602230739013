<template>
  <div class="container mx-auto dark:text-white text-center">
    <h1>Not Found</h1>
  </div>
</template>

<script>
export default {
  //
}
</script>
